<template>
  <v-dialog
    v-model="dialog"
    :max-width="sMaxWidth"
    open-delay="300"
    persistent
    scrollable
    transition="dialog-top-transition"
  >
    <template #activator="{ on }">
      <sheet
        v-show="showDialog"
        class="mt-4"
        dense
        no-padding
        outlined
        v-on="on"
      >
        <v-subheader v-text="$t('invoice.payment.method')" />
        <template v-for="(obModelPM, index) in invoicePaymentMethods">
          <v-list-item
            :key="`invoicepm-${index}`"
            :class="{
              'orange--text': wrongBalance,
              'green--text': !wrongBalance,
            }"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="
                  obModelPM.payment_method
                    ? obModelPM.payment_method.name
                    : 'undefined'
                "
              />
              <v-list-item-subtitle
                v-if="obModelPM.config.amount"
                v-text="
                  getCurrencyFormat(
                    obModelPM.config.amount,
                    obModelPM.currency_id
                  )
                "
              />
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title
                class="text-right"
                v-text="getCurrencyFormat(obModelPM.amount)"
              />
            </v-list-item-content>
          </v-list-item>
        </template>
      </sheet>
    </template>

    <v-card :loading="loading">
      <v-toolbar color="grey lighten-2" flat>
        <v-toolbar-title>{{ $t("invoice.payment.method") }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          :disabled="!wrongBalance"
          :elevation="0"
          color="primary"
          @click="addInvoicePaymentMethod()"
        >
          <icon-add class="mr-2" />
          {{ $t("add") }}
        </v-btn>

        <template #extension>
          <v-tabs v-model="tab" grow show-arrows>
            <template v-for="obTabIPM in obCollection.getModels()">
              <v-tab
                :key="`invoice-pm-tab-${obTabIPM.id}`"
                :disabled="
                  (lessBalance || !wrongBalance) &&
                  !usedPaymentMethod.includes(obTabIPM.code)
                "
              >
                {{ obTabIPM.name }}
              </v-tab>
            </template>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items
        v-if="dialog && showDialog"
        v-model="tab"
        class="grey lighten-3 mb-4"
      >
        <template v-for="(obPaymentMethod, idx) in obCollection.getModels()">
          <v-tab-item
            :key="`invoice-pm-tab-content-${obPaymentMethod.id}-${idx}`"
          >
            <invoice-payment-methods-table
              v-if="tab === idx"
              v-model="obInvoice"
              :balance="fBalance"
              :company-payment-methods="
                $_.get(obCompanyPaymentMethods, obPaymentMethod.code, [])
              "
              :currency-id-list="arCurrencyId"
              :open="open"
              :payment-method="obPaymentMethod"
            />
          </v-tab-item>
        </template>
      </v-tabs-items>

      <v-card-text>
        <invoice-payment-method-amounts />
      </v-card-text>

      <v-card-actions>
        <v-alert
          v-if="(canUpdateAccountingCode || editable) && !!obInvoice.id"
          dense
          text
          type="info"
        >
          {{ $t("invoice.payment.method.must.be.applied") }}
        </v-alert>
        <v-spacer />
        <v-btn
          v-if="(canUpdateAccountingCode || editable) && !!obInvoice.id"
          color="primary"
          text
          @click="onApply"
        >
          {{ $t("apply") }}
        </v-btn>

        <v-btn color="primary" text @click="close">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import type {
  InvoicePaymentMethodData,
  PaymentMethodData,
} from "@planetadeleste/vue-mc-gw";
import {
  AccountCollection,
  InvoiceMovementType,
  InvoiceType,
  PaymentMethod,
  PaymentMethodCollection,
} from "@planetadeleste/vue-mc-gw";
import { type Dictionary, filter } from "lodash";
import {
  assign,
  debounce,
  find,
  get,
  groupBy,
  isEmpty,
  isString,
  map,
  uniq,
} from "lodash";
import { Component, Mixins } from "vue-property-decorator";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import InvoicePaymentMethodsTable from "@/modules/invoices/components/paymentmethod/InvoicePaymentMethodsTable.vue";
import { EventBus } from "@/services/event-bus";
import { type DebounceFunction, round } from "@/plugins/helpers";
import { positive } from "@/plugins/helpers";
import useMath from "@/composables/math";
import useInvoice from "@/composables/invoice";
import { array, object, string, ValidationError } from "yup";
import InvoicePaymentMethodAmounts from "@/modules/invoices/components/paymentmethod/InvoicePaymentMethodAmounts.vue";
import { canModuleAccess } from "@/services/moduleAccess";
import { ConfigModule } from "@/store/config";
import { AppModule } from "@/store/app";
import type { CurrencyData } from "@planetadeleste/vue-mc-shopaholic";

const sDefaultPMCode = "EFECTIVO";
const { math } = useMath();

@Component({
  components: { InvoicePaymentMethodAmounts, InvoicePaymentMethodsTable },
})
export default class InvoicePaymentMethods extends Mixins(InvoiceMixin) {
  dialog = false;
  open = false;
  loading = false;
  tab = 0;
  obCollection: PaymentMethodCollection = new PaymentMethodCollection();
  obCompanyPaymentMethods: Dictionary<
    [PaymentMethodData, ...PaymentMethodData[]]
  > = {};
  arCurrencyId: number[] = [];
  fnUpdateAmountDebounced!: DebounceFunction;

  get defaultPM() {
    return this.obCollection.find({ code: sDefaultPMCode });
  }

  get companyCurrencyIdList() {
    return ConfigModule.companyCurrencyIdList;
  }

  get arCurrencyList(): CurrencyData[] {
    return AppModule.currencies.getModelList() as CurrencyData[];
  }

  get defaultPMName() {
    return this.invoicePaymentMethods.length || !this.defaultPM
      ? this.$t("invoice.payment.method.others")
      : this.defaultPM.name;
  }

  get sPaid() {
    return this.getCurrencyFormat(positive(this.fPaid));
  }

  get sBalance() {
    return this.getCurrencyFormat(this.fBalance);
  }

  get editable() {
    return !this.signed || this.isReceived;
  }

  get wrongBalance() {
    return this.editable && this.fBalance !== 0;
  }

  get lessBalance() {
    return this.editable && this.fBalance < 0;
  }

  get highBalance() {
    return this.editable && this.fBalance > 0;
  }

  get sRules() {
    if (!this.fPaid || this.signed) {
      return "";
    }

    // const fRoundedValue = positive(
    //   math.subtract(this.fTotal, this.fTotalRounded)
    // );
    // const fValue = fRoundedValue < 0.01 /*|| this.isRefoundOrDebit*/
    //   ? this.fTotal
    //   : this.fTotalRounded;

    const fValue = this.fTotalRounded;

    return {
      min_value: this.isDebt ? false : 0,
      double: true,
      // numeric: this.isSale,
      is: fValue > 0 ? fValue : false,
    };
  }

  get isSale() {
    return this.iMovementType === InvoiceMovementType.CODE_SALES;
  }

  /**
   * Return true if invoice is refound (Devolución - NC) or Debit (ND)
   * @return {boolean}
   */
  get isRefoundOrDebit() {
    return [
      InvoiceMovementType.CODE_REFOUND,
      InvoiceMovementType.CODE_DEBIT_NOTE,
    ].includes(this.iMovementType);
  }

  get sMaxWidth() {
    let fWidth = "100%";
    const obBreakPoint = this.$vuetify.breakpoint;

    if (obBreakPoint.xl) {
      fWidth = "1200px";
    }

    if (obBreakPoint.lg) {
      fWidth = "800px";
    }

    return fWidth;
  }

  get usedPaymentMethod() {
    return map(this.invoicePaymentMethods, (obItem) => {
      const obPaymentMethod = this.obCollection.find({
        id: obItem.payment_method_id,
      });
      return obPaymentMethod ? obPaymentMethod.code : null;
    });
  }

  get showDialog() {
    return (
      (this.obInvoice.is_cash ||
        [
          InvoiceMovementType.CODE_DEBT,
          InvoiceMovementType.CODE_BUY,
          InvoiceMovementType.CODE_PAY,
          15,
        ].includes(this.iMovementType)) &&
      this.fTotal !== 0 &&
      this.sTypeCode !== InvoiceType.CODE_ERESGUARDO
    );
  }

  created() {
    this.fnUpdateAmountDebounced = debounce(this.updateAmount, 500);
    EventBus.on("invoice.paymentMethodConfig.dialog.closed", () => {
      this.open = false;
    });

    EventBus.on("invoice.change.price", () => {
      this.fnUpdateAmountDebounced();
    });

    EventBus.on("invoice.update.paymentmethods", () => {
      this.fnUpdateAmountDebounced();
    });

    EventBus.on("invoice.apply.discounts", () => {
      this.fnUpdateAmountDebounced();
    });
  }

  beforeDestroy() {
    EventBus.off("invoice.paymentMethodConfig.dialog.closed");
    EventBus.off("invoice.change.price");
    EventBus.off("invoice.apply.discounts");
    EventBus.off("invoice.update.paymentmethods");
  }

  mounted() {
    this.load();
  }

  close() {
    this.dialog = false;
  }

  async load() {
    this.loading = true;
    let iPaymentMethodIdList: number[] = [];

    if (this.isReceiptType && !this.signed) {
      const obAccountCollection = new AccountCollection();
      await obAccountCollection
        .filterBy({
          company: this.company.id,
          withPaymentMethod: 1,
        })
        .list();
      iPaymentMethodIdList = uniq(obAccountCollection.map("paymentmethod_id"));
      this.arCurrencyId = uniq(obAccountCollection.map("currency_id"));
    }

    this.obCollection.clear();
    this.obCollection.inInvoice();

    await this.obCollection.list();
    this.obCollection.sort((model) => {
      return model.code == sDefaultPMCode ? "0" : model.name;
    });

    // if (iPaymentMethodIdList.length) {
    //   this.obCollection = this.obCollection.filter((obModel: PaymentMethod) =>
    //     iPaymentMethodIdList.includes(obModel.id)
    //   );
    // }

    if (!this.editable && this.usedPaymentMethod.length) {
      this.obCollection = this.obCollection.filter((model: PaymentMethod) => {
        return this.usedPaymentMethod.includes(model.code);
      });
    }

    await this.loadCompanyPaymentMethods();
    this.assignDefault();
    this.loading = false;
  }

  async loadCompanyPaymentMethods(): Promise<void> {
    if (!this.company) {
      return;
    }

    const obResponse = await this.company.getPaymentMethods();
    if (obResponse) {
      const arItems = obResponse.getData().data;
      // @ts-ignore
      this.obCompanyPaymentMethods = groupBy(arItems, "code");
    }
  }

  assignDefault() {
    if (
      this.invoicePaymentMethods.length ||
      !this.defaultPM ||
      !this.fTotalRounded
    ) {
      return;
    }

    this.addInvoicePaymentMethod(this.defaultPM);
  }

  addInvoicePaymentMethod(obPaymentMethod?: PaymentMethod) {
    this.open = false;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (!obPaymentMethod || obPaymentMethod! instanceof PaymentMethod) {
      if (!this.obCollection.length || !this.fTotalRounded) {
        return;
      }

      obPaymentMethod = this.obCollection.models[this.tab];
      if (!obPaymentMethod) {
        return;
      }
    }

    const arUsedCurrencies = map(this.invoicePaymentMethods, "currency_id");

    if (
      obPaymentMethod.code === sDefaultPMCode &&
      arUsedCurrencies.includes(this.obInvoice.currency_id) &&
      this.companyCurrencyIdList.length === arUsedCurrencies.length
    ) {
      this.updateAmount();
      return;
    }

    // @ts-ignore
    const obInvoicePM: InvoicePaymentMethodData = {
      payment_method_id: obPaymentMethod.id,
      currency_id: this.obInvoice.currency_id,
      amount: positive(round(this.fBalance)),
      sort_order: this.invoicePaymentMethods.length + 1,
      payment_method: obPaymentMethod,
      rate: this.currency ? Number(this.currency.rate) : 0,
      config: { amount: 0 },
    };

    const arCompanyPaymentMethods =
      this.isSale && obPaymentMethod.code === "CHEQUE"
        ? []
        : get(this.obCompanyPaymentMethods, obPaymentMethod.code, []);

    if (!isEmpty(arCompanyPaymentMethods)) {
      const obCompanyPaymentMethod = arCompanyPaymentMethods[0];
      const obPivotConfig = get(obCompanyPaymentMethod, "pivot.config", {});
      obInvoicePM.config = assign(obInvoicePM.config, obPivotConfig);
    }

    const arInvoicePaymentMethods = [...this.invoicePaymentMethods];
    arInvoicePaymentMethods.push(obInvoicePM);

    if (obPaymentMethod.code !== sDefaultPMCode) {
      this.open = true;
    }

    this.obInvoice.set("payment_methods", arInvoicePaymentMethods);
  }

  updateAmount() {
    if (!this.invoicePaymentMethods.length || this.fPaid == this.fBalance) {
      return;
    }

    const arInvoicePaymentMethods = [...this.invoicePaymentMethods];
    // const fValue = this.fTotalRounded - this.fPaid;
    const obInvoicePM = find(arInvoicePaymentMethods, {
      currency_id: this.obInvoice.currency_id,
    }) as InvoicePaymentMethodData | undefined;

    if (!obInvoicePM) {
      return;
    }

    obInvoicePM.amount = round(
      math.add(positive(obInvoicePM.amount), this.fBalance)
    );
    arInvoicePaymentMethods.splice(0, 1, obInvoicePM);
    this.obInvoice.set("payment_methods", arInvoicePaymentMethods);
  }

  /**
   * Validates the invoice payment methods against a predefined schema.
   *
   * Checks if the accounting module is accessible and then validates the payment methods
   * using the schema. If validation fails, it returns the first error.
   *
   * @return {boolean|string} True if validation succeeds, otherwise the first error message
   */
  async validate(): Promise<boolean | string> {
    try {
      if (!canModuleAccess("accounting")) {
        return true;
      }

      const obSchema = object({
        payment_methods: array()
          .label(this.$t("invoice.payment.method") as string)
          .of(
            object({
              config: object({
                account_code: string().required(
                  this.$t(
                    "error.code.account.required.in.payment.methods"
                  ) as string
                ),
              }),
            })
          ),
      });

      await obSchema.validate({
        payment_methods: this.invoicePaymentMethods,
      });

      return true;
      // @ts-ignore
    } catch (err: ValidationError) {
      return err.errors[0];
    }
  }

  async onApply() {
    // Validate payment method
    const sResponse = await this.validate();

    if (isString(sResponse)) {
      this.$toast.error(sResponse);
      return;
    }

    // Prompt confirmation
    const sMessage = this.$t("ask.assign.record");
    const bRes = await this.$confirm(sMessage as string, {
      color: "warning",
    });

    if (!bRes) {
      return;
    }

    const { obj } = useInvoice(this.obInvoice);

    if (!obj) {
      return;
    }

    this.loading = true;
    const obInvoiceData = await obj.updatePaymentMethods();

    if (obInvoiceData && get(obInvoiceData, "is_processable")) {
      this.obInvoice.set("is_processable", true);
    }

    this.loading = false;
    this.close();
  }
}
</script>
